$(document).ready(function () {
    $('.imageSlider').slick({
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        dots: false,
        easing: 'easeInOut',
        centerMode: true,
        centerPadding: '25%',
        slidesToShow: 1,
        infinite: true,
    });

});