$(document).ready(function () {

    $('.likeButton').on('click', ($event) => {
        let data = $($event.currentTarget).data();

        let heart = $($event.currentTarget.children[0]);
        if (!heart.hasClass('loved')) {

            heart.addClass('loved');

            $.post(`/athome/like/${data.postid}`).then(res => {
                let like = $(`#like_${data.postid}`);
                if (like[0]) {
                    like[0].innerHTML = parseInt(like[0].innerHTML) + 1;
                }
                else {
                    let el = `<span id="like_${data.postid}" class="text-left">1 x <i class="fa fa-fw fa-heart loved"></i></span>`
                    $(`#nolike_${data.postid}`).append(el);
                }

            }).catch(err => { console.error(err) })
        }
    })

});