class SignUp {
    constructor(

    ) {

    }

    setup() {
        let signUp = $('#submitSignUp');
        signUp.click(() => {
            let form = $('#signUpForm').serializeArray();
            var json = {};
            $.each(form, function () {
                json[this.name] = this.value || '';
            });
            send(json)
        });
    }

    another() {
        let form = $('#signUpForm');
        form.show();
        form[0].reset();
        let success = $('#signUpSuccess');
        success.hide();
    }
}


$(document).ready(function () {
    const signUp = new SignUp();
    signUp.setup();
    $('#singUpAnother').on('click', () => {
        signUp.another();
    });

    $("#unsubscribeUser").on('click', () => {
        unsubscribe();
    })



    grecaptcha.ready(function () {
        grecaptcha.execute('6Leu27MUAAAAAEux9FeHXbjzG64wRIYV14xUEvFw', { action: 'signUp' }).then(function (token) {
            verify({ token: token });
        });
    });
})

function verify(data) {
    $.ajax({
        url: '/api/emaillist/verify',
        method: 'POST',
        data: data
    }).done(result => {
        if (!result.success || result.score < 0.7) {
            let form = $('#signUpForm');
            form.hide();
        }
    }).fail(err => {
        let msg = JSON.parse(err.responseText);
        alert(msg.message);
    })
}

function send(data) {
    grecaptcha.execute('6Leu27MUAAAAAEux9FeHXbjzG64wRIYV14xUEvFw', { action: 'signUp' }).then(function (token) {
        data.token = token;
        $.ajax({
            url: '/api/emaillist',
            method: 'POST',
            dataType: 'json',
            data: data
        }).done(result => {
            let signUp = $('#signUpForm');
            signUp.hide();
            let success = $('#signUpSuccess');
            success.show();
        }).fail(err => {
            let msg = JSON.parse(err.responseText);
            alert(msg.message);
        })
    });
}

function unsubscribe() {
    let sure = confirm('Are you sure you want to unsubscribe to all DCYC newsletter emails?');
    if (sure) {
        grecaptcha.execute('6Leu27MUAAAAAEux9FeHXbjzG64wRIYV14xUEvFw', { action: 'unsubscribe' }).then(function (token) {
            let data = {};
            data.token = token;
            let params = new URLSearchParams(window.location.search);
            data._id = params.get('q');
            $.ajax({
                url: '/api/emaillist/unsubscribe',
                method: 'POST',
                dataType: 'json',
                data: data
            }).done(result => {
                $('#unsubscribeRow').hide();
                $('#doneRow').show();
            }).fail(err => {
                let msg = JSON.parse(err.responseText);
                alert(msg.message);
            })
        });
    }
}

